import { Link, Outlet } from 'react-router-dom'

export default function App() {
  return (
    <>
      <main>
        <Outlet />
      </main>
      <footer className="p-8 bg-dark text-light">
        <Link to="/privacy">Privacy</Link>
      </footer>
    </>
  )
}

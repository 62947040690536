import { Navigate, RouterProvider, createBrowserRouter } from 'react-router-dom'

import App from './App'
import Home from './views/Home'
import NotFound from './views/NotFound'
import Privacy from './views/Privacy'

export default function AppRouter() {
  const router = createBrowserRouter([
    {
      path: '',
      element: <App />,
      children: [
        { path: '', element: <Home /> },
        { path: 'privacy', element: <Privacy /> }
      ]
    },
    { path: '404', element: <NotFound /> },
    { path: '*', element: <Navigate to="/404" replace /> }
  ])

  return <RouterProvider router={router} />
}

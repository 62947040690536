export default function Home() {
  return (
    <div className="min-h-screen p-8 flex justify-center items-center">
      <div className="flex flex-col items-center justify-center">
        <h1>Save Energy</h1>
        <div className="h-6"></div>
        <a href="/app" className="text-light rounded-full bg-primary text-center font-bold text-xl px-10 py-4">
          App on web
        </a>
        <div className="h-8"></div>
        <a href="https://play.google.com/store/apps/details?id=eu.saveenergy.mobile&hl=en">
          <img src="/assets/img/google-play-badge.png" alt="Google Play" className="w-48" />
        </a>
      </div>
    </div>
  )
}
